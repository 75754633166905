import styled from 'styled-components'

import ADA from '../../assets/currencies/ada.png'
import AED from '../../assets/currencies/aed.png'
import AUD from '../../assets/currencies/aud.png'
import BGN from '../../assets/currencies/bgn.png'
import BHD from '../../assets/currencies/bhd.png'
import BNB from '../../assets/currencies/bnb.png'
import BRL from '../../assets/currencies/brl.png'
import BTC from '../../assets/currencies/btc.png'
import CAD from '../../assets/currencies/cad.png'
import CLP from '../../assets/currencies/clp.png'
import CHF from '../../assets/currencies/chf.png'
import CZK from '../../assets/currencies/czk.png'
import DKK from '../../assets/currencies/dkk.png'
import DOGE from '../../assets/currencies/doge.png'
import ETH from '../../assets/currencies/eth.png'
import EUR from '../../assets/currencies/eur.png'
import EURK from '../../assets/currencies/eurk.png'
import GBP from '../../assets/currencies/gbp.png'
import HKD from '../../assets/currencies/hkd.png'
import HRK from '../../assets/currencies/hrk.png'
import HUF from '../../assets/currencies/huf.png'
import INR from '../../assets/currencies/inr.png'
import JPY from '../../assets/currencies/jpy.png'
import LTC from '../../assets/currencies/ltc.png'
import MXN from '../../assets/currencies/mxn.svg'
import NOK from '../../assets/currencies/nok.png'
import NZD from '../../assets/currencies/nzd.png'
import PEN from '../../assets/currencies/pen.png'
import PLN from '../../assets/currencies/pln.png'
import RON from '../../assets/currencies/ron.png'
import SAR from '../../assets/currencies/sar.png'
import SEK from '../../assets/currencies/sek.png'
import SOL from '../../assets/currencies/sol.png'
import TRY from '../../assets/currencies/try.png'
import USD from '../../assets/currencies/usd.png'
import USDC from '../../assets/currencies/usdc.png'
import USDT from '../../assets/currencies/usdt.png'
import XRP from '../../assets/currencies/xrp.png'

export const CurrencyIconWrapper = styled.span<{ width: string; height: string }>`
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;

  &.card {
    border: 1px solid #ffffff;
  }
  &.circle {
    border-radius: 50%;
  }

  &.ADA {
    //prettier-ignore
    background-image: url("${ADA}");
  }
  &.AED {
    //prettier-ignore
    background-image: url("${AED}");
  }
  &.AUD {
    //prettier-ignore
    background-image: url("${AUD}");
  }
  &.BGN {
    //prettier-ignore
    background-image: url("${BGN}");
  }
  &.BHD {
    //prettier-ignore
    background-image: url("${BHD}");
  }
  &.BNB {
    //prettier-ignore
    background-image: url("${BNB}");
  }
  &.BRL {
    //prettier-ignore
    background-image: url("${BRL}");
  }
  &.BTC {
    //prettier-ignore
    background-image: url("${BTC}");
  }
  &.CAD {
    //prettier-ignore
    background-image: url("${CAD}");
  }
  &.CLP {
    //prettier-ignore
    background-image: url("${CLP}");
  }
  &.CHF {
    //prettier-ignore
    background-image: url("${CHF}");
  }
  &.CZK {
    //prettier-ignore
    background-image: url("${CZK}");
  }
  &.DKK {
    //prettier-ignore
    background-image: url("${DKK}");
  }
  &.DOGE {
    //prettier-ignore
    background-image: url("${DOGE}");
  }
  &.ETH {
    //prettier-ignore
    background-image: url("${ETH}");
  }
  &.EUR {
    //prettier-ignore
    background-image: url("${EUR}");
  }
  &.EURK-ERC20 {
    //prettier-ignore
    background-image: url("${EURK}");
  }
  &.GBP {
    //prettier-ignore
    background-image: url("${GBP}");
  }
  &.HKD {
    //prettier-ignore
    background-image: url("${HKD}");
  }
  &.HRK {
    //prettier-ignore
    background-image: url("${HRK}");
  }
  &.HUF {
    //prettier-ignore
    background-image: url("${HUF}");
  }
  &.INR {
    //prettier-ignore
    background-image: url("${INR}");
  }
  &.JPY {
    //prettier-ignore
    background-image: url("${JPY}");
  }
  &.LTC {
    //prettier-ignore
    background-image: url("${LTC}");
  }
  &.MXN {
    //prettier-ignore
    background-image: url("${MXN}");
  }
  &.NOK {
    //prettier-ignore
    background-image: url("${NOK}");
  }
  &.NZD {
    //prettier-ignore
    background-image: url("${NZD}");
  }
  &.PEN {
    //prettier-ignore
    background-image: url("${PEN}");
  }
  &.PLN {
    //prettier-ignore
    background-image: url("${PLN}");
  }
  &.RON {
    //prettier-ignore
    background-image: url("${RON}");
  }
  &.SAR {
    //prettier-ignore
    background-image: url("${SAR}");
  }
  &.SEK {
    //prettier-ignore
    background-image: url("${SEK}");
  }
  &.SOL {
    //prettier-ignore
    background-image: url("${SOL}");
  }
  &.TRY {
    //prettier-ignore
    background-image: url("${TRY}");
  }
  &.USD {
    //prettier-ignore
    background-image: url("${USD}");
  }
  &.USDC,
  &.USDC-ERC20,
  &.USDC-TRC20 {
    //prettier-ignore
    background-image: url("${USDC}");
  }
  &.USDT,
  &.USDT-ERC20,
  &.USDT-TRC20 {
    //prettier-ignore
    background-image: url("${USDT}");
  }
  &.XRP {
    //prettier-ignore
    background-image: url("${XRP}");
  }
`
