import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { StyledForm } from '../WalletCheckout.styled'
import { SelectAccount } from '../../../components'
import { PaymentContainer } from '../../CheckoutPayment/Checkout.styled'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'

import { tr } from 'mmfintech-commons'
import { actions } from 'mmfintech-checkout-commons'
import { ThunkDispatch } from 'redux-thunk'
import { PaymentMethodEnum } from 'mmfintech-commons-types'

export const WalletCheckoutAccount = ({ selectedAccount, setSelectedAccount, onSuccess, onCancel }) => {
  const { session, walletAccounts, walletAccountsError, walletPreviewError, walletPreviewFetching } = useSelector(
    ({
      checkout: {
        session,
        walletAccounts,
        walletAccountsError,
        walletAccountsFetching,
        walletPreviewError,
        walletPreviewFetching
      }
    }) => ({
      session,
      walletAccounts,
      walletAccountsError,
      walletAccountsFetching,
      walletPreviewError,
      walletPreviewFetching
    }),
    shallowEqual
  )

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (selectedAccount) {
      void dispatch(
        actions.checkout.payWithWalletPreview(selectedAccount.id, PaymentMethodEnum.KINGDOM_WALLET, onSuccess)
      )
    }
  }

  useEffect(() => {
    if (session) {
      const { currency } = session
      void dispatch(actions.checkout.payWithWalletAccounts(currency, null))
    }
    // eslint-disable-next-line
  }, [session])

  return (
    <PaymentContainer>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <div className='account-title'>Pay With Wallet</div>

        <SelectAccount
          noShadow
          className='mb-3'
          accounts={walletAccounts}
          label='Account'
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />

        <ErrorDisplay error={[walletAccountsError, walletPreviewError]} />

        <Button
          type='submit'
          color='primary'
          className='mb-1'
          text='Continue'
          loading={walletPreviewFetching}
          disabled={!selectedAccount}
          data-test='button-submit'
        />
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.LOGIN.BUTTON_BACK', 'Back')}
          disabled={walletPreviewFetching}
          onClick={onCancel}
          data-test='button-back'
        />
      </StyledForm>
    </PaymentContainer>
  )
}
