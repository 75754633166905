import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { MerchantPane } from '../../components'
import { WalletCheckoutOtp } from './WalletCheckoutOtp'
import { WalletCheckoutLogin } from './WalletCheckoutLogin'
import { WalletCheckoutAccount } from './WalletCheckoutAccount'
import { WalletCheckoutPreview } from './WalletCheckoutPreview'

export const WalletCheckout = ({ sessionId, handlePay, onCancel }) => {
  const { logo, session } = useSelector(
    ({ checkout: { logo, session } }) => ({
      logo,
      session
    }),
    shallowEqual
  )

  const [step, setStep] = useState(1)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const handleLoginResponse = (response: any) => {
    if (response?.data?.challenge) {
      setStep(2)
    } else {
      setStep(3)
    }
  }

  useEffect(() => {
    setStep(1)
  }, [])

  return (
    <>
      {step < 4 && <MerchantPane session={session} logo={logo} />}

      {step === 1 && <WalletCheckoutLogin sessionId={sessionId} onSuccess={handleLoginResponse} onCancel={onCancel} />}
      {step === 2 && <WalletCheckoutOtp onSuccess={() => setStep(3)} onCancel={onCancel} />}
      {step === 3 && (
        <WalletCheckoutAccount
          onSuccess={() => setStep(4)}
          onCancel={onCancel}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
      )}
      {step === 4 && (
        <>
          <WalletCheckoutPreview
            onCancel={() => setStep(3)}
            selectedAccount={selectedAccount}
            handlePay={() => handlePay(selectedAccount.id)}
          />
        </>
      )}
    </>
  )
}
