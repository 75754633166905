import styled from 'styled-components'

import KingdomBackground from '../../assets/images/kingdom-bank-bg.png'

export const CheckoutWrapper = styled.div`
  grid-row: 1 / 4;
  grid-column: left-column / right-column;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  //prettier-ignore
  background-image: url("${KingdomBackground}");
  background-color: #000000;
  background-repeat: repeat-y;
  background-position: center top;
  background-size: cover;

  .err-msg-wrapper {
    justify-content: center;
  }
`

export const CheckoutHeader = styled.div`
  width: 100%;
  height: 12rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .logo {
    width: 100%;
    height: 100%;
    grid-column: 2;
    display: grid;
    place-items: center;
  }

  .right {
    display: grid;
    place-items: center flex-end;
    margin-right: 6rem;
  }
`
