import { DataRow } from '../index'
import {
  MerchantPaneAmountContainer,
  MerchantPaneInfoContainer,
  MerchantPaneLogoContainer,
  MerchantPaneWrapper
} from './MerchantPane.styled'

import { formatMoney, tr } from 'mmfintech-commons'

type MerchantPaneProps = {
  session: any
  logo?: string
}

export const MerchantPane = ({ session, logo }: MerchantPaneProps) => {
  if (session) {
    const { amount, currency, foreignTransactionId, merchantName } = session || {}

    return (
      <MerchantPaneWrapper>
        <MerchantPaneAmountContainer>
          <MerchantPaneLogoContainer>
            <div>
              <div className='label'>{tr('CHECKOUT.PAYMENT.AMOUNT', 'Amount')}</div>
              <div className='value'>{formatMoney(amount, currency)}</div>
            </div>
            {logo && <img className='logo' src={logo} alt='' />}
          </MerchantPaneLogoContainer>
        </MerchantPaneAmountContainer>
        <MerchantPaneInfoContainer>
          <DataRow label={tr('CHECKOUT.PAYMENT.PAY_TO', 'Pay to')} value={merchantName} />
          <DataRow label={tr('CHECKOUT.PAYMENT.TRANSACTION_ID', 'Transaction ID')} value={foreignTransactionId} />
        </MerchantPaneInfoContainer>
      </MerchantPaneWrapper>
    )
  }

  return null
}
