export const InfoIcon = ({ color = 'black', stroke = 'white' }) => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='13' cy='13' r='13' fill={color} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5735 7V9.11765H14.0735V7H12.5735ZM10.5 11.9853H12.5735V17.5441H10.5V19.0441H13.3235H14.0735H16.1471V17.5441H14.0735V11.2353V10.4853H13.3235H10.5V11.9853Z'
      fill={stroke}
    />
  </svg>
)
