import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import QRCode from 'qrcode.react'

import { Button } from 'mmfintech-portal-commons'
import { LabelValue, WarningMessage } from '../../../components'
import { CryptoPreviewTitle, CryptoPreviewWrapper } from './PreviewCrypto.styled'

import { actions, usePolling } from 'mmfintech-checkout-commons'
import { findCurrencyPrecision, formatFloat, formatMoney, tr } from 'mmfintech-commons'

import { ThunkDispatch } from 'redux-thunk'

type PreviewCryptoProps = {
  sessionId?: string
  data?: any
  onBack?: () => void
  onClose?: () => void
}

const STATUS_POLLING_TIMEOUT = 30000 // 30 seconds

export const PreviewCrypto = ({ sessionId, data, onClose, onBack }: PreviewCryptoProps) => {
  const { currencies } = useSelector(({ common: { currencies } }) => ({ currencies }), shallowEqual)

  const { address, currency, tag, processingAmount } = data || {}

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()
  const polling = usePolling(() => reloadSessionStatus(), 'status.reload')

  const handleStatusFetchSuccess = (response: any): void => {
    const { status } = response?.data || {}

    switch (status) {
      case 'PROCESSED':
      case 'CONFIRMED':
        history.push('/success')
        break

      case 'FAILED':
      case 'CANCELLED':
        history.push('/fail')
        break

      default:
        polling.start(STATUS_POLLING_TIMEOUT)
    }
  }

  const handleStatusFetchFail = () => {
    polling.start(STATUS_POLLING_TIMEOUT)
  }

  const reloadSessionStatus = () => {
    void dispatch(actions.checkout.fetchSession(sessionId, handleStatusFetchSuccess, handleStatusFetchFail))
  }

  useEffect(() => {
    if (sessionId) {
      polling.start(STATUS_POLLING_TIMEOUT)
    }
  }, [])

  return (
    <>
      <CryptoPreviewWrapper>
        <CryptoPreviewTitle>{tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.TITLE', 'Details')}</CryptoPreviewTitle>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.ADDRESS', 'Deposit address')}
          value={address}
          enableCopy={true}
          dataTest='address'
        />

        <p className='text-center code bordered'>
          <QRCode value={address} renderAs='svg' size={150} />
        </p>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.TAG', 'Tag')}
          value={tag}
          dataTest='tag'
          enableCopy={true}
        />

        <LabelValue
          label={tr('FRONTEND.BANKING.COMMON.AMOUNT_LABEL', 'Amount')}
          value={formatMoney(processingAmount, currency, currencies)}
          dataTest='amount'
          enableCopy={true}
          copyValue={formatFloat(processingAmount, findCurrencyPrecision(currency, currencies))}
        />

        <div className='mb-2' />

        {typeof onClose === 'function' && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
            onClick={onClose}
            data-test='button-close'
          />
        )}
        {typeof onBack === 'function' && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.BUTTONS.BACK', 'Back')}
            onClick={onBack}
            data-test='button-back'
          />
        )}
      </CryptoPreviewWrapper>

      <WarningMessage currency={currency} />
    </>
  )
}
