import styled from 'styled-components'

export const OtpCountdown = styled.div`
  display: inline-block;
  position: absolute;
  top: 1.5rem;
  right: 2rem;

  color: #939ea7;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;

  &.expired {
    color: #ee2b2b;
  }
`
