import cn from 'classnames'

import { PaymentMethodButton, PaymentMethodChecked, PaymentMethodImage } from '../SelectMethod.styled'

import { tr } from 'mmfintech-commons'

import checkedIcon from '../../../assets/icons/checked.svg'

export const MethodButton = ({ name, selected, setSelected }) => (
  <PaymentMethodButton className={cn({ selected })} onClick={() => setSelected(name)} data-test='button-payment-method'>
    <PaymentMethodImage className={cn('image-' + name.toLowerCase(), { selected })}>
      {selected && (
        <PaymentMethodChecked>
          <img src={checkedIcon} alt='' />
        </PaymentMethodChecked>
      )}
    </PaymentMethodImage>
    {name === 'KINGDOM_WALLET' ? 'Kingdom Bank Wallet' : tr('METADATA.PAYMENT_OPTIONS.' + name, name)}
  </PaymentMethodButton>
)
