import styled from 'styled-components'

export const PaymentContainer = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  position: relative;

  min-height: 20rem;
  margin-top: 2rem;
  padding: 3rem 5rem 4rem;

  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;

  .link-wrap {
    margin: 0.5rem 0 2.4rem;
    text-align: right;

    a {
      color: rgba(0, 97, 232, 0.6);
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 102.52%;
      text-decoration: none;
      outline: none;
    }
  }

  .lower-input-section {
    display: flex;
    flex-flow: row nowrap;
    .input-wrapper {
      .left-icon {
        padding-left: 3.5rem;
      }
      &:last-of-type {
        border-left: 1px solid rgba(238, 238, 238, 1);
        .input-inner-wrapper {
          padding: 0 1rem;
        }
      }
    }
  }

  .input-wrapper {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .otp-title {
    color: #000000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    text-align: center;
  }
  .otp-subtitle {
    color: #000000;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;

    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    span {
      display: block;
      font-weight: 700;
      overflow-wrap: anywhere;
    }
  }
  #otp {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.2rem;
    letter-spacing: 1.2rem;

    text-align: center;

    width: 20rem;
    margin: 0 auto;
    padding-bottom: 1rem;

    border: none;
    border-bottom: 1px solid #eeeeee;
    background-color: #ffffff;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }
    &:-ms-input-placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }
    &::placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }

    &.wrong {
      border-color: #ff0431;
    }
  }
  .account-title {
    margin-bottom: 3rem;
  }

  .buttons {
    margin-top: 3.5rem;

    button {
      height: 6rem;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.2rem;

      margin-bottom: 0.8rem;
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`

export const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 0 2rem;
  width: 100%;

  font-size: 2.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  a {
    color: #8f99a3;
    text-decoration: none;

    &:hover {
      color: #ffffff;
    }
  }
`

export const SignupLinkWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  padding: 2rem;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 102.52%;
  letter-spacing: -0.025em;

  position: relative;

  a {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    background-color: #000000;

    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6rem;
    text-decoration: none;
    outline: none;

    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.2rem 1.8rem;
    margin-left: 2.5rem;
  }
`

export const OtpResendMessage = styled.div`
  color: #000000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7rem;
  text-align: center;

  margin-top: 2rem;

  span {
    margin-top: 0.5rem;
    display: block;
    text-decoration: underline;
    cursor: pointer;
    color: #2f80ed;
  }
`
