import styled from 'styled-components'

export const BankwirePreviewWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 100%;
  max-width: 50rem;

  background: #ffffff;

  font-size: 1.4rem;

  margin: 2rem 0;
  padding: 2rem;
  border-radius: 8px;

  @media (min-width: 500px) {
    padding: 3rem 5rem 4rem;
  }

  .row {
    margin-bottom: 1rem;

    .label {
      margin-bottom: 0;
    }
  }

  .text-bold {
    font-weight: 600;
  }
`

export const BankwirePreviewTitle = styled.div`
  width: 100%;
  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.9rem;
  text-align: center;

  margin-bottom: 4rem;
`

export const WarningMessageWrapper = styled.div`
  background: rgba(30, 99, 255, 0.08);
  padding: 1.5rem;
  margin: 0 0 3rem;

  color: #454a54;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
