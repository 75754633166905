import styled from 'styled-components'

export const PreviewFrameStyled = styled.div`
  width: 100%;
  max-width: 80rem;
  padding: 2.5rem 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 750px) {
    padding: 1rem 0;
  }

  iframe {
    border: none;
    min-height: 75vh;
    width: 100%;
  }
`
