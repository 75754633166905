import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { tr } from 'mmfintech-commons'

import { MethodPane } from './MethodPane'
import { MethodButton } from './MethodButton'
import {
  PaymentMethodsContainer,
  PaymentMethodsEmpty,
  PaymentMethodsTitle,
  PaymentMethodsWrapper
} from './SelectMethod.styled'

export const SelectMethod = ({ className, method, setMethod ,countryCode}) => {
  const { checkoutOptions } = useSelector(
    ({ checkout: { checkoutOptions } }) => ({
      checkoutOptions
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(checkoutOptions) && checkoutOptions.length === 1) {
      setMethod(checkoutOptions[0].paymentMethod)
    }
    // eslint-disable-next-line
  }, [checkoutOptions])

  if (Array.isArray(checkoutOptions) && checkoutOptions.length) {
    if (checkoutOptions.length > 1) {
      return (
        <PaymentMethodsWrapper>
          <PaymentMethodsTitle>Payment With</PaymentMethodsTitle>
          <PaymentMethodsContainer className={className}>
            {checkoutOptions.map(option => (
              <MethodButton
                key={option.paymentMethod}
                name={option.paymentMethod}
                selected={option.paymentMethod === method}
                setSelected={setMethod}
              />
            ))}
          </PaymentMethodsContainer>
        </PaymentMethodsWrapper>
      )
    }

    return (
      <PaymentMethodsWrapper>
        <PaymentMethodsTitle>Paying With</PaymentMethodsTitle>
        <MethodPane selected={method} />
      </PaymentMethodsWrapper>
    )
  }

  return countryCode && (
    <PaymentMethodsWrapper>
      <PaymentMethodsEmpty>{tr('CHECKOUT.PAYMENT.NO_METHODS', 'No payment methods available')}</PaymentMethodsEmpty>
    </PaymentMethodsWrapper>
  )
}
