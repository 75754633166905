import { Button } from 'mmfintech-portal-commons'
import { ErrorIcon } from '../../icons'
import { AlertContent, AlertWrapper } from './ErrorFallback.styled'

export const ErrorFallback = ({ resetErrorBoundary }) => {
  return (
    <AlertWrapper>
      <AlertContent>
        <ErrorIcon />

        <p>An unexpected error occurred</p>

        {resetErrorBoundary ? (
          <Button color='alternative' onClick={resetErrorBoundary} text='Go back to the home page' />
        ) : null}
      </AlertContent>
    </AlertWrapper>
  )
}
