import React, { ReactNode, useEffect, useState } from 'react'

import cn from 'classnames'
import { formatMoney, isValidArray, isValidString, tr, useDropDownMenu } from 'mmfintech-commons'

import { CurrencyIcon } from '../CurrencyIcon'
import {
  AccountButtonStyled,
  AccountListStyled,
  AccountStyled,
  SelectAccountError,
  SelectAccountLabel,
  SelectAccountStyled,
  SelectAccountWrapper
} from './SelectAccount.styled'

import { AccountBalanceOut } from 'mmfintech-commons-types'

import ChevronDown from '../../assets/icons/chevron-down.svg'
import ChevronUp from '../../assets/icons/chevron-up.svg'

type SelectAccountProps = {
  staticDisplay?: boolean
  accounts?: AccountBalanceOut[]
  error?: string
  label?: ReactNode
  selectedAccount?: AccountBalanceOut
  setSelectedAccount?: (account: AccountBalanceOut) => void
  noShadow?: boolean
  hasExchangeAssets?: boolean
  className?: string
  showId?: boolean
  noBorder?: boolean
}

export const SelectAccount = (props: SelectAccountProps) => {
  const {
    staticDisplay = false, // if set, static account display will be presented
    accounts, // list of accounts to select from
    error, // error message (if needed)
    label, // label above the selection box
    selectedAccount, // selected account
    setSelectedAccount, // selected account setter
    noShadow = false,
    hasExchangeAssets = true,
    className,
    showId = false, // shows account id next to name
    noBorder = false // hides border
  } = props

  const [preselectAccountId, setPreselectAccountId] = useState(0)

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const toggle = (e: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation()
    !staticDisplay && toggleDropDown()
  }

  const handleSelect = (account: AccountBalanceOut) => {
    hideDropDown()
    setSelectedAccount(account)
  }

  const { balance, currencyCode, name, id } = selectedAccount || {}

  useEffect(() => {
    if (preselectAccountId) {
      const find = accounts.find(account => account.id === preselectAccountId)
      setPreselectAccountId(0)
      handleSelect(find)
    }
    // eslint-disable-next-line
  }, [accounts])

  return (
    <SelectAccountWrapper className={cn('select-account-wrapper', className)} data-test='account-dropdown'>
      {label && <SelectAccountLabel>{label}</SelectAccountLabel>}
      <SelectAccountStyled>
        <AccountButtonStyled
          className={cn({ 'no-shadow': noShadow, static: staticDisplay, error: error?.length, 'no-border': noBorder })}
          onClick={toggle}>
          {selectedAccount ? (
            <span className='account-info'>
              <span className='icon'>
                <CurrencyIcon currency={currencyCode} width='26px' height='26px' />
              </span>
              <span className='content'>
                <span className='balance'>
                  <b>{tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:</b>{' '}
                  {formatMoney(balance, currencyCode)}
                </span>
                <span>
                  {name} {showId && id ? ` - ID: ${id}` : ''}
                </span>
              </span>
            </span>
          ) : (
            <span className='no-account'>{tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.PLACEHOLDER', 'Select Account')}</span>
          )}
          {!staticDisplay && (
            <span className='chevron'>
              <img src={visible ? ChevronUp : ChevronDown} alt='' />
            </span>
          )}
        </AccountButtonStyled>

        {!staticDisplay && (
          <AccountListStyled opened={visible} data-test='accounts-menu-content'>
            {!isValidArray(accounts) && (
              <AccountStyled data-test='all-accounts-menu-item' className='disabled'>
                <span className='icon'>
                  <span className='icon-all'>N/A</span>
                </span>
                <span className='content'>
                  <span className='balance'>
                    <b>
                      {hasExchangeAssets
                        ? tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.NO_ACCOUNTS', 'No Eligible Accounts')
                        : tr(
                            'FRONTEND.COMPONENTS.SELECT_ACCOUNT.CANNOT_EXCHANGE',
                            'Currently this asset cannot be exchanged'
                          )}
                    </b>
                  </span>
                </span>
              </AccountStyled>
            )}
            {accounts?.map(account => {
              const { id, currencyCode, balance, name: accName } = account

              return (
                <AccountStyled
                  key={id}
                  onClick={() => handleSelect(account)}
                  data-test='account-menu-item'
                  className='option'>
                  <span className='icon'>
                    <CurrencyIcon currency={currencyCode} width='26px' height='26px' />
                  </span>
                  <span className='content'>
                    <span className='balance'>
                      {tr('FRONTEND.COMPONENTS.SELECT_ACCOUNT.BALANCE_LABEL', 'Balance')}:{' '}
                      {formatMoney(balance, currencyCode)}
                    </span>
                    <span>{accName}</span>
                  </span>
                </AccountStyled>
              )
            })}
          </AccountListStyled>
        )}
      </SelectAccountStyled>

      {isValidString(error) && <SelectAccountError>{error}</SelectAccountError>}
    </SelectAccountWrapper>
  )
}
