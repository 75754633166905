import { shallowEqual, useSelector } from 'react-redux'

import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import {
  DataRow,
  SelectAccount,
  MerchantPaneAmountContainer,
  MerchantPaneInfoContainer,
  MerchantPaneLogoContainer,
  MerchantPaneNetAmountContainer,
  MerchantPaneWrapper
} from '../../../components'

import { formatMoney, tr } from 'mmfintech-commons'

export const WalletCheckoutPreview = ({ selectedAccount, handlePay, onCancel }) => {
  const { logo, session, walletPreview, walletPayError, walletPayFetching } = useSelector(
    ({ checkout: { logo, session, walletPreview, walletPayError, walletPayFetching } }) => ({
      logo,
      session,
      walletPreview,
      walletPayError,
      walletPayFetching
    }),
    shallowEqual
  )

  const { foreignTransactionId, merchantName } = session || {}
  const { fee, feeCurrency, walletAmount, walletCurrency, processingAmount, processingCurrency } = walletPreview || {}

  return (
    <MerchantPaneWrapper>
      <MerchantPaneAmountContainer>
        <MerchantPaneLogoContainer>
          <div>
            <div className='label'>{tr('CHECKOUT.PAYMENT.AMOUNT', 'Amount')}</div>
            <div className='value'>{formatMoney(processingAmount, processingCurrency)}</div>
            {fee > 0 ? <div className='fee'>Fee {formatMoney(fee, feeCurrency)} included</div> : null}
          </div>
          {logo && <img className='logo' src={logo} alt='' />}
        </MerchantPaneLogoContainer>
      </MerchantPaneAmountContainer>
      {fee > 0 || walletCurrency !== processingCurrency ? (
        <MerchantPaneNetAmountContainer>
          <div className='label'>{tr('CHECKOUT.PAYMENT.NET_AMOUNT', 'Net Amount')}</div>
          <div className='value'>{formatMoney(walletAmount, walletCurrency)}</div>
        </MerchantPaneNetAmountContainer>
      ) : null}
      <MerchantPaneInfoContainer>
        <div className='subtitle'>Payment Details</div>

        <DataRow label={tr('CHECKOUT.PAYMENT.PAY_TO', 'Pay to')} value={merchantName} />
        <DataRow label={tr('CHECKOUT.PAYMENT.TRANSACTION_ID', 'Transaction ID')} value={foreignTransactionId} />

        <div className='subtitle mt-3'>Pay With Wallet</div>

        <SelectAccount noShadow staticDisplay className='mb-3' label='Account' selectedAccount={selectedAccount} />

        <ErrorDisplay error={walletPayError} />

        <Button
          type='button'
          color='primary'
          className='mb-1'
          text='Pay'
          onClick={handlePay}
          loading={walletPayFetching}
          data-test='button-submit'
        />
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.LOGIN.BUTTON_CANCEL', 'Cancel')}
          disabled={walletPayFetching}
          onClick={onCancel}
          data-test='button-back'
        />
      </MerchantPaneInfoContainer>
    </MerchantPaneWrapper>
  )
}
