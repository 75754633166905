import React from 'react'
import * as ReactDOMClient from 'react-dom/client'

import thunk from 'redux-thunk'
import { Provider } from 'react-redux'

import { configureStore } from '@reduxjs/toolkit'
import { checkoutApi, reducers } from 'mmfintech-checkout-commons'
import { enableMapSet, enableES5 } from 'immer'

import './i18n'

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'

import App from './App'

enableMapSet()
enableES5()

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk, checkoutApi.middleware)
})

const rootContainer = document.getElementById('checkout-app')
const root = ReactDOMClient.createRoot(rootContainer)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
