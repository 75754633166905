import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import cn from 'classnames'
import moment from 'moment'

import { OtpCountdown } from './DurationTimer.styled'

export const DurationTimer = ({ setCustomExpired }) => {
  const { challenge } = useSelector(
    ({ auth: { challenge } }) => ({
      challenge
    }),
    shallowEqual
  )

  const { validUntil } = challenge || {}

  // eslint-disable-next-line
  const end = useMemo(() => (validUntil ? moment(validUntil) : null), [challenge])

  const [expired, setExpired] = useState(false)
  const [timeDiff, setTimeDiff] = useState(end ? moment.duration(end.diff(moment())) : null)

  useEffect(() => {
    if (timeDiff && end) {
      const timerId = setInterval(() => {
        setTimeDiff(currDiff => moment.duration(currDiff?.subtract(1, 'seconds'), 'seconds'))
      }, 1000)

      if (timeDiff.seconds() < 0) {
        clearInterval(timerId)
        setExpired(true)
        setCustomExpired && setCustomExpired(true)
      }
      return () => {
        clearInterval(timerId)
      }
    }
    //eslint-disable-next-line
  }, [timeDiff])

  const formatTimer = (num: number) => {
    if (num && num > 0) {
      if (num < 10) {
        return `0${num}`
      } else {
        return num
      }
    }
    return '00'
  }

  return (
    <OtpCountdown className={cn({ expired })}>
      {formatTimer(timeDiff?.minutes())}:{formatTimer(timeDiff?.seconds())}
    </OtpCountdown>
  )
}
