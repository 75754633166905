import styled from 'styled-components'

export const ContentWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1490px) 1fr;
  grid-template-rows: 9rem auto;
  grid-template-areas: 'header header header' 'left-column main-content right-column' 'footer footer footer';
  grid-row-gap: 0;

  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: Inter, sans-serif;
  background-color: #f4f6f6;
`
