import { useHistory } from 'react-router-dom'

import { PreviewPix } from './PreviewPix'
import { PreviewFrame } from './PreviewFrame'
import { PreviewCrypto } from './PreviewCrypto'
import { PreviewMessage } from './PreviewMessage'
import { PreviewBankwire } from './PreviewBankwire'
import { PreviewInstructions } from './PreviewInstructions'

import { isProduction } from '../../settings'
import { isMobileDevice, redirectToExternalUrl } from 'mmfintech-commons'

import { PaymentResultTypeEnum, PaymentSessionStatusEnum } from 'mmfintech-commons-types'

export const DepositResult = ({ session, sessionId, response, logEvent }) => {
  const { result, resultType, processingAmount, status } = response || {}

  const history = useHistory()

  if (status === PaymentSessionStatusEnum.FAIL) {
    history.push('/fail')
    return
  }

  switch (resultType) {
    case PaymentResultTypeEnum.MESSAGE:
      return <PreviewMessage result={result} />

    case PaymentResultTypeEnum.INSTRUCTIONS:
      return <PreviewInstructions result={result} session={session} sessionId={sessionId} />

    case PaymentResultTypeEnum.REDIRECT_URL:
      logEvent('redirected_to_provider')
      if (typeof result === 'string') {
        redirectToExternalUrl(result)
      } else {
        const { redirectUrl } = result || {}
        redirectToExternalUrl(redirectUrl)
      }
      break

    case PaymentResultTypeEnum.BANKWIRE_DETAILS:
      logEvent('manual_bank_transfer_details_shown')
      return <PreviewBankwire result={result} session={session} sessionId={sessionId} />

    case PaymentResultTypeEnum.QR_CODE:
      return <PreviewPix value={result} />

    // case PaymentResultTypeEnum.CRYPTO_ADDRESS_DETAILS:
    case PaymentResultTypeEnum.CHECKOUT_CRYPTO_ADDRESS_DETAILS:
      return <PreviewCrypto sessionId={sessionId} data={{ ...result, ...{ processingAmount } }} />

    case PaymentResultTypeEnum.IFRAME_URL:
      if (isMobileDevice()) {
        logEvent('redirected_to_provider')
        redirectToExternalUrl(result)
      } else {
        logEvent('open_provider_in_frame')
        return <PreviewFrame url={result} />
      }
      break

    default:
      if (!isProduction && resultType) {
        console.log(`DepositResult: Unknown result type "${resultType}"`)
      }
  }

  if (status === PaymentSessionStatusEnum.SUCCESS) {
    history.push('/success')
  }

  return null
}
