export const ErrorIcon = ({ color = '#f16969' }) => (
  <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M21 31L31 21' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21 21L31 31' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M21.1841 8.77667C24.9406 7.77011 28.9243 8.03122 32.5173 9.51949C36.1103 11.0078 39.1118 13.64 41.0563 17.008C43.0008 20.376 43.7797 24.2916 43.2721 28.1473C42.7644 32.0031 40.9987 35.5836 38.2488 38.3336C35.4988 41.0835 31.9183 42.8493 28.0625 43.3569C24.2067 43.8645 20.2912 43.0857 16.9232 41.1411C13.5552 39.1966 10.9229 36.1951 9.43467 32.6021C7.9464 29.0091 7.6853 25.0254 8.69185 21.2689'
      stroke={color}
      strokeWidth='1.5'
    />
    <path
      d='M34.994 49.3333C32.2031 50.4098 29.1705 51 26 51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1C39.8071 1 51 12.1929 51 26C51 28.922 50.4987 31.7268 49.5775 34.3333'
      stroke={color}
      strokeWidth='1.5'
    />
  </svg>
)
