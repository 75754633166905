export const SuccessIcon = ({ color = '#44cc00', stroke = 'white' }) => (
  <svg width='55' height='55' viewBox='0 0 55 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M55 27.5C55 42.6878 42.6878 55 27.5 55C12.3122 55 0 42.6878 0 27.5C0 12.3122 12.3122 0 27.5 0C42.6878 0 55 12.3122 55 27.5Z'
      fill={color}
    />
    <path
      d='M12.2221 27.4983L23.7137 36.1169C24.1275 36.4273 24.7097 36.3708 25.0562 35.9868L48.5 10'
      stroke={stroke}
      strokeWidth='3'
      strokeLinecap='round'
    />
  </svg>
)
