import cn from 'classnames'

import { MethodPaneWrapper, PaymentMethodImage } from '../SelectMethod.styled'

import { tr } from 'mmfintech-commons'

export const MethodPane = ({ selected }) =>
  selected ? (
    <MethodPaneWrapper>
      <span>
        {selected === 'KINGDOM_WALLET' ? 'Kingdom Bank Wallet' : tr('METADATA.PAYMENT_OPTIONS.' + selected, selected)}
      </span>
      <PaymentMethodImage className={cn('icon', 'image-' + selected.toLowerCase())} />
    </MethodPaneWrapper>
  ) : null
