import React from 'react'

import Checkout from './views/CheckoutPayment'

const QRPayment = React.lazy(() => import('./views/QRPayment'))
const CheckoutError = React.lazy(() => import('./views/CheckoutError'))
const CheckoutFailure = React.lazy(() => import('./views/CheckoutFailure'))
const CheckoutPolling = React.lazy(() => import('./views/CheckoutPolling'))
const CheckoutSuccess = React.lazy(() => import('./views/CheckoutSuccess'))

const routes = [
  { path: '/fail', component: CheckoutFailure },
  { path: '/error', component: CheckoutError },
  { path: '/success', component: CheckoutSuccess },
  { path: '/processing', component: CheckoutPolling },
  { path: '/qr-payment', component: QRPayment },
  { path: '/', exact: true, component: Checkout },
  { path: '*', redirect: '/' }
]

export default routes
