import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import cn from 'classnames'

import { DurationTimer } from '../../../components'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { OtpResendMessage, PaymentContainer } from '../../CheckoutPayment/Checkout.styled'

import { actions } from 'mmfintech-checkout-commons'
import { checkSingleValue } from 'mmfintech-commons'

import { ThunkDispatch } from 'redux-thunk'

interface WalletCheckoutOtp { 
  onSuccess: (response: any) => void
  onCancel?: any
}

export const WalletCheckoutOtp = ({ onSuccess, onCancel }:WalletCheckoutOtp) => {
  const { challenge, resendError, resendFetching, verifyError, verifyFetching } = useSelector(
    ({ auth: { challenge, resendError, resendFetching, verifyError, verifyFetching } }) => ({
      challenge,
      resendError,
      resendFetching,
      verifyError,
      verifyFetching
    }),
    shallowEqual
  )

  const [expired, setExpired] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [verificationCode, setVerificationCode] = useState('')

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const { challengeId, sentTo, twoFactorType } = challenge || {}

  const handleChange = ({ target }) => {
    const newValue = checkSingleValue(target.value, { validation: 'numeric' })
    setVerificationCode(newValue)
  }

  const handleResend = () => {
    void dispatch(
      actions.auth.challengeResend(challengeId, () => {
        setAttempts(0)
        setVerificationCode('')
      })
    )
  }

  useEffect(() => {
    if (verificationCode.length === 4 && attempts < 3) {
      setAttempts(attempts + 1)
      void dispatch(actions.auth.challengeVerify(challengeId, verificationCode, onSuccess))
    }
    // eslint-disable-next-line
  }, [verificationCode])

  useEffect(() => {
    return () => {
      dispatch(actions.auth.challengeCleanup())
    }
  }, [dispatch])

  return (
    <PaymentContainer className='vertically-centered' data-test='wallet-checkout-otp'>
      <div className='otp-title'>{twoFactorType === 'SMS' ? 'SMS Verification' : 'E-mail Verification'}</div>
      <div className='otp-subtitle'>
        Please, enter the verification code that we've sent to:
        <span>{sentTo}</span>
      </div>

      <ErrorDisplay error={verifyError} />

      {resendFetching ? null : resendError ? (
        <>
          <ErrorDisplay error={resendError} />
          {onCancel && (
            <Button type='button' color='secondary' text='Cancel' onClick={onCancel} data-test='button-cancel' />
          )}
        </>
      ) : (
        <>
          <div className='text-center'>
            <input
              id='otp'
              type='tel'
              className={cn({
                complete: verificationCode?.length === 4,
                wrong: verificationCode?.length === 4 && verifyError != null
              })}
              autoComplete='off'
              onChange={handleChange}
              value={verificationCode}
              maxLength={4}
              autoFocus
              placeholder='Enter Code'
              disabled={expired || attempts === 3}
              data-test='tel-input'
            />
          </div>

          <OtpResendMessage>
            {attempts < 3 ? (
              <>
                Haven't received it?
                <span onClick={handleResend}>Re-send code</span>
              </>
            ) : (
              'No more verification attempts.'
            )}
          </OtpResendMessage>

          <div className='mt-3'>
            {onCancel && (
              <Button
                type='button'
                color='primary'
                text='Cancel'
                disabled={verifyFetching}
                onClick={onCancel}
                data-test='button-cancel'
              />
            )}
          </div>
        </>
      )}

      {attempts < 3 && !resendFetching && <DurationTimer setCustomExpired={setExpired} />}
    </PaymentContainer>
  )
}
